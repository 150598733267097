<template>
  <div />
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  import { setBankidStartUrl, setFrejaStartUrl } from '../../../../utils'

  export default {
    name: 'AuthenticationListener',
    data: () => ({
      secretPoll: null,
      secretPollInterval: 1000,
      collectPoll: null,
      collectPollInterval: 2000,
      timeout: null,
      timeoutAge: 180000
    }),
    computed: {
      ...mapGetters('authentication', ['getAuthentication']),
      ...mapGetters('misc', ['getIsDesktop']),
      autostarttoken () {
        return this.getAuthentication?.autoStartToken
      }
    },
    mounted () {
      if (this.AUTHENTICATION.VERIFY_EID) {
        this.verifyEid()
      }

      this.EVENT_BUS.$on('authentication:initiate', ({ eidProvider, authenticatedAction, authenticatingFor, showModal = false }) => {
        this.initiateEidLogin({ eidProvider, authenticatedAction, authenticatingFor, showModal })
      })

      this.EVENT_BUS.$on('authentication:cancel', this.cancel)
      this.EVENT_BUS.$on('authentication:kill:secret', this.killSecretPoll)
      this.EVENT_BUS.$on('authentication:kill:collect', this.killCollectPoll)
      this.EVENT_BUS.$on('authentication:bankid:open', this.openBankId)
      this.EVENT_BUS.$on('authentication:freja:open', this.openFreja)
    },
    destroyed () {
      this.EVENT_BUS.$off('authentication:initiate')
      this.EVENT_BUS.$off('authentication:cancel')
      this.EVENT_BUS.$off('authentication:kill:secret')
      this.EVENT_BUS.$off('authentication:kill:collect')
      this.EVENT_BUS.$off('authentication:bankid:open')
      this.EVENT_BUS.$off('authentication:freja:open')
    },
    methods: {
      ...mapActions('authentication', [
        'setAuthentication',
        'initiateEid',
        'secretEid',
        'collectEid',
        'cancelEid',
        'createOauth'
      ]),
      ...mapActions('messages', ['setModalMessage']),
      ...mapActions('form', ['setField']),
      async initiateEidLogin ({ eidProvider, authenticatedAction, authenticatingFor, showModal }) {
        this.killSecretPoll()
        this.killCollectPoll()

        if (authenticatedAction) await this.setAuthentication({ authenticatedAction })
        if (authenticatingFor) await this.setAuthentication({ authenticatingFor })
        if (showModal) await this.setAuthentication({ showModal: true })

        await this.setField({
          group: 'authentication',
          field: 'eidProvider',
          value: eidProvider
        })

        const response = await this.initiateEid()
        if (!response) return

        if (this.AUTHENTICATION.POLL_SECRET && this.getIsDesktop) {
          await this.secretEid()

          if (eidProvider === this.EID_PROVIDERS.BANKID) {
            await this.pollSecret()
          }
        }

        if (this.AUTHENTICATION.POLL_EID) {
          if (eidProvider === this.EID_PROVIDERS.BANKID && !this.getIsDesktop) {
            await this.openBankId()
          }

          if (eidProvider === this.EID_PROVIDERS.FREJA && !this.getIsDesktop) {
            await this.openFreja()
          }

          await this.pollCollect()
        }

        if (this.AUTHENTICATION.REDIRECT_EID) {
          if (!response || this.TESTCAFE) return

          setTimeout(() => {
            window.location.href = response
          }, 300)
        }
      },
      async openBankId () {
        if (this.TESTCAFE) return

        try {
          window.location.href = setBankidStartUrl(this.autostarttoken)
        } catch (e) {}
      },
      async openFreja () {
        if (this.TESTCAFE) return

        try {
          window.location.href = setFrejaStartUrl(this.autostarttoken)
        } catch (e) {}
      },
      async pollSecret () {
        this.killSecretPoll()

        this.secretPoll = setInterval(async () => {
          const complete = await this.secretEid()

          if (complete === false && !this.getAuthentication.token) {
            this.killSecretPoll()
          }
        }, this.secretPollInterval)

        this.setTimeout()
      },
      async pollCollect () {
        this.killCollectPoll()

        this.collectPoll = setInterval(async () => {
          const complete = await this.collectEid()

          if (complete) {
            this.killCollectPoll()
            await this.createAuth()
          } else if (complete === false && !this.getAuthentication.token) {
            this.killCollectPoll()
          }
        }, this.collectPollInterval)

        this.setTimeout()
      },
      async verifyEid () {
        const resolved = await this.collectEid()
        if (resolved) await this.createAuth()
      },
      async createAuth () {
        const resolved = await this.createOauth()
        if (!resolved) return

        setTimeout(async () => {
          const authenticatedAction = this.getAuthentication?.authenticatedAction

          if (authenticatedAction) {
            await this.$store.dispatch(authenticatedAction, {}, { root: true })
            this.setAuthentication({ authenticatedAction: null, showModal: false })
          } else {
            this.setAuthentication({ loading: false, showModal: false })
          }

          this.EVENT_BUS.$emit('refresh-tokens:start-poll')
        }, 0)
      },
      setTimeout () {
        this.timeout = setTimeout(() => {
          this.setModalMessage({
            group: 'authentication',
            name: 'expiredTransaction'
          })

          if (!this.secretPoll || !this.collectPoll) return

          this.killSecretPoll()
          this.killCollectPoll()
        }, this.timeoutAge)
      },
      killSecretPoll () {
        clearInterval(this.secretPoll)
        this.secretPoll = null

        clearTimeout(this.timeout)
        this.timeout = null
      },
      killCollectPoll () {
        clearInterval(this.collectPoll)
        this.collectPoll = null

        clearTimeout(this.timeout)
        this.timeout = null
      },
      cancel () {
        this.killSecretPoll()
        this.killCollectPoll()

        this.cancelEid()
      }
    }
  }
</script>
